angular.module('httpService', []).service 'httpService', [
  '$http'
  ($http) ->

    @post = (url, data, callback) ->
      $http.post(rootPath + url, if data != '' then angular.toJson(data) else '').success (response) ->
        callback response

    @get = (url, callback) ->
      $http.get(rootPath + url).success (response) ->
        callback response

    @delete = (url, callback) ->
      $http.delete(rootPath + url).success (response) ->
        callback response

    return
]
